@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
/* @font-face {
  font-family: "GNElliotCyWeb-Regular";
  font-display: swap;
  src: local("GNElliotCyWeb-Regular"),
    url("../fonts/GNElliotCyWeb-Regular.woff") format("woff"),
    url("../fonts/GNElliotCyWeb-Regular.woff2") format("woff2"),
    url("../fonts/GNElliotCyWeb-Regular.eot") format("embedded-opentype"),
    url("../fonts/GNElliotCyWeb-Regular.ttf") format("truetype"),
    url("../fonts/GNElliotCyWeb-Regular.svg") format("svg");
} */
